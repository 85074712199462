import React from "react"
import LayoutSite from "../components/layouts/layoutSite"
import PaginationButton from "../components/crumbs/paginationButton"
import { useStaticQuery, graphql } from "gatsby"
import Boxes from "../components/crumbs/boxes"
import PageSeo from "../components/crumbs/pageSeo"
import LayoutList from "../components/layouts/layoutList"
import Helmet from "react-helmet"

export default function Home() {
  const { allStrapiArticle } = useStaticQuery(graphql`
    query {
      allStrapiArticle(limit: 25, sort: {order: DESC, fields: publish}, filter: {status: {eq: "live"}, category: {name: {eq: "Belföld"}}}) {
        edges {
          node {
              title
              category {
                  name
              }
              cover {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
              }
              excerpt
          }
        }
      }
    }
  `)

  const refreshUrl = "/belfold"  
  const next = `/kereses${refreshUrl}/1`
  const prev = null

  const boxNo = 1
  const articles = allStrapiArticle.edges.map(item => <Boxes box={boxNo} article={item.node}/>)

  return (
    <>
      {/*<Helmet><script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script></Helmet>*/}
      <PageSeo
        title="Minden Percben | Belföld"
        description="Minden amiről tudnia érdemes hazánkban. Mert minden perc érdekes!"
        keywords="belföldi hírek, politikai hírek, hazai hírek, magyar hírek"
        canonical="https://www.mindenpercben.hu/belfold"
        canonicalMobile = "https://m.mindenpercben.hu/belfold"        
      />
      <LayoutSite 
        refreshUrl={refreshUrl}
        content={
          <>
            <LayoutList articles={articles} />
            <PaginationButton next={next} prev={prev}/>
          </>
        }
      />
    </>
  )
}